export const ZOOM = {
  DEFAULT: 10,
  MIN: 2,
  MAX: 12,
};

export const PADDING = {
  mobile: {
    top: 70,
    bottom: 240,
    right: 70,
    left: 70,
  },
  desktop: {
    top: 70,
    bottom: 70,
    right: 70,
    left: 70,
  },
};

export const REFIT_PADDING = 0.05;
