import { REFIT_PADDING } from '@/constants/map';

/**
 * Takes an array of geopoints and calculates a bounding box that will display them all.
 */
export const generateBoundingBox = (
  locations: { lon: number; lat: number }[] = [],
): [[number, number], [number, number]] => {
  // Valid ranges for lat and lon
  let latMin = 90;
  let latMax = -90;
  let lngMin = 180;
  let lngMax = -180;

  locations.forEach(location => {
    lngMin = Math.min(lngMin, location.lon);
    lngMax = Math.max(lngMax, location.lon);
    latMin = Math.min(latMin, location.lat);
    latMax = Math.max(latMax, location.lat);
  });

  return [
    [lngMin - REFIT_PADDING, latMin - REFIT_PADDING],
    [lngMax + REFIT_PADDING, latMax + REFIT_PADDING],
  ];
};
