import groupBy from 'lodash/groupBy';
import { createSelector } from 'reselect';

import { REFIT_PADDING } from '@/constants/map';
import { TRootState } from '@/redux/rootReducer';
import { ICampgroundData } from '@/services/types/search/campgrounds/id';
import { IData } from '@/services/types/search/rentals/id';
import { generateBoundingBox } from '@/utility/map';
import {
  ISearchResultTile,
  mapCampgroundToTile,
  mapRentalToTile,
} from '@/utility/mapSearchResultToTile';

type TSearchData = TRootState['search']['data'];
type TSearchMeta = TRootState['search']['meta'];
type TQueryParams = TRootState['queryParams'];

export type TBounds = [[number, number], [number, number]] | [];

export const getMapBounds = createSelector<
  TRootState,
  TSearchData,
  ICampgroundData[] | undefined,
  TSearchMeta,
  TBounds
>(
  state => state.search.data,
  state => state.search.campgrounds?.data,
  state => state.search.meta,

  (searchData, searchCampgroundsData, searchMeta) => {
    // If we have enough data points, generate a new bounding box for map and render that box.
    const results = searchData?.length
      ? searchData
      : searchCampgroundsData?.length
        ? searchCampgroundsData
        : [];
    const geopoints = results.map(result => result.geopoint);
    if (geopoints.length > 1) return generateBoundingBox(geopoints);

    const fallbackCoords =
      geopoints.length === 1 ? geopoints[0] : { lon: searchMeta?.lng, lat: searchMeta?.lat };

    const MIDWEST_COORDS = { lat: 39.0997, lon: -94.5786 }; // Kansas City, MO

    const validFallbackCoords = {
      lat: fallbackCoords?.lat ?? MIDWEST_COORDS?.lat,
      lon: fallbackCoords?.lon ?? MIDWEST_COORDS?.lon,
    };

    // Center map around fallback
    return [
      [validFallbackCoords.lon - REFIT_PADDING, validFallbackCoords.lat - REFIT_PADDING],
      [validFallbackCoords.lon + REFIT_PADDING, validFallbackCoords.lat + REFIT_PADDING],
    ];
  },
);
export interface IMapMarkerData {
  lat: number;
  lon: number;
  rentals: ISearchResultTile[];
}

const stringifyRentalGeopoint = ({ geopoint }: IData | ICampgroundData): string =>
  `${geopoint.lat},${geopoint.lon}`;

const transformRentalGroupToMapMarkerData =
  (queryParams: TQueryParams) =>
  (rentals: IData[]): IMapMarkerData => {
    return {
      // @ts-expect-error fixable: unchecked index access
      ...rentals[0].geopoint,
      rentals: rentals.map(rental =>
        mapRentalToTile({ rental, queryParams, favorites: { data: [] } }),
      ),
    };
  };

const transformCampgroundGroupToMapMarkerData =
  (queryParams: TQueryParams) =>
  (campgrounds: ICampgroundData[]): IMapMarkerData => {
    return {
      // @ts-expect-error fixable: unchecked index access
      ...campgrounds[0].geopoint,
      rentals: campgrounds.map(campground => mapCampgroundToTile({ campground, queryParams })),
    };
  };

export const getMapMarkers = createSelector<
  TRootState,
  TSearchData,
  ICampgroundData[] | undefined,
  TQueryParams,
  IMapMarkerData[]
>(
  state => state.search.data,
  state => state.search.campgrounds?.data,
  state => state.queryParams,
  (results, campgroundResults, queryParams) => {
    if (results?.length) {
      return Object.values(groupBy(results, stringifyRentalGeopoint)).map(
        transformRentalGroupToMapMarkerData(queryParams),
      );
    } else if (campgroundResults?.length) {
      return Object.values(groupBy(campgroundResults, stringifyRentalGeopoint)).map(
        transformCampgroundGroupToMapMarkerData(queryParams),
      );
    }
    return [];
  },
);
